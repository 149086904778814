import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { RadioButton, SelectionGroup } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const RadioButtonExample = () => {
  const [selectedItem, setSelectedItem] = React.useState('1');
  const onChange = event => {
    setSelectedItem(event.target.value);
  };
  return <>
      <RadioButton id="radio1" name="example1" label="Label" value="1" checked={selectedItem === '1'} onChange={onChange} mdxType="RadioButton" />
      <RadioButton id="radio2" name="example1" label="Label" value="2" checked={selectedItem === '2'} onChange={onChange} mdxType="RadioButton" />
    </>;
};
export const DefaultRadioButtonExample = () => {
  const [selectedItem2, setSelectedItem2] = React.useState('1');
  const onChange2 = event => {
    setSelectedItem2(event.target.value);
  };
  return <>
      <RadioButton id="radio3" name="example2" label="Label" value="1" checked={selectedItem2 === '1'} onChange={onChange2} mdxType="RadioButton" />
      <RadioButton id="radio4" name="example2" label="Label" value="2" checked={selectedItem2 === '2'} onChange={onChange2} mdxType="RadioButton" />
    </>;
};
export const GroupExample = () => {
  const [selectedItem, setSelectedItem] = React.useState('1');
  const onChange = event => {
    setSelectedItem(event.target.value);
  };
  return <SelectionGroup label="Radio button group" mdxType="SelectionGroup">
      <RadioButton id="v-radio1" name="v-radio" value="1" label="Option" checked={selectedItem === '1'} onChange={onChange} mdxType="RadioButton" />
      <RadioButton id="v-radio2" name="v-radio" value="2" label="Option" checked={selectedItem === '2'} onChange={onChange} mdxType="RadioButton" />
      <RadioButton id="v-radio3" name="v-radio" value="3" label="Option" checked={selectedItem === '3'} onChange={onChange} mdxType="RadioButton" />
      <RadioButton id="v-radio4" name="v-radio" value="4" label="Option" checked={selectedItem === '4'} onChange={onChange} mdxType="RadioButton" />
    </SelectionGroup>;
};
export const _frontmatter = {
  "slug": "/components/radio-button",
  "title": "RadioButton",
  "navTitle": "RadioButton"
};
const layoutProps = {
  RadioButtonExample,
  DefaultRadioButtonExample,
  GroupExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <RadioButtonExample mdxType="RadioButtonExample" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`If the user can select more than one option from a list, use `}<InternalLink href="/components/checkbox" mdxType="InternalLink">{`checkboxes`}</InternalLink>{` instead.`}</li>
      <li parentName="ul">{`Radio button label should always clearly describe what will happen if the specific option is chosen. A good practice is to keep labels a maximum of three words long.`}</li>
      <li parentName="ul">{`It is recommended to have a default option checked for radio button groups Radio button groups.`}</li>
      <li parentName="ul">{`When there are more than 4 options, consider switching to `}<InternalLink href="/components/dropdown" mdxType="InternalLink">{`dropdown`}</InternalLink>{` component.`}</li>
      <li parentName="ul">{`If the choices are not mutually exclusive, use `}<InternalLink href="/components/checkbox" mdxType="InternalLink">{`checkboxes`}</InternalLink>{` component instead. Also see `}<InternalLink href="/foundation/guidelines/checkbox-radiobutton-toggle" mdxType="InternalLink">{`guidelines for choosing between radiobuttons, checkboxes, and toggles.`}</InternalLink></li>
      <li parentName="ul">{`If Radio buttons are related to each other, use `}<InternalLink href="/components/selection-group" mdxType="InternalLink">{`selection group`}</InternalLink>{` to achieve that.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <DefaultRadioButtonExample mdxType="DefaultRadioButtonExample" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "group",
      "style": {
        "position": "relative"
      }
    }}>{`Group`}<a parentName="h4" {...{
        "href": "#group",
        "aria-label": "group permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Related radio buttons can be grouped together with the Selection group component. See `}<InternalLink href="/components/selection-group" mdxType="InternalLink">{`HDS selection group page`}</InternalLink>{` for full documentation.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <GroupExample mdxType="GroupExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      